<template>
    <div>
        <div class="home-bg1">
            <div class="cycle-wrapper">
                <div class="breadcrumb">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item>技术与服务</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <div class="content">
                    <div class="con-title">产品概述</div>
                    <div class="con-text">
                        迪倍特设备运维管理云平台（迪倍特巡检云）是集设备运维管理、数据储存、数据处理为一体的云计算平台，该平台通过对设备的巡检、点检、维修以及运行状态等数据信息进行收集、传输、储存、统计、分析、预测和维护，来提高设备工作效率和维护维修效率，系统自动生成相关任务、报表及设备运行报告，迪倍特以建设信息化设施设备运维系统，保障高效化安全生产体系目标，融合标准化、流程化、协作化、数字化、精细化管理理念，为企业打造全员参与的高校协同的设备运维管理SAAS平台。
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    methods: {
        
    },
    mounted() {
        
    }
}
</script>
<style lang="less" scoped>
// web端样式
.home-bg1 {
    user-select: none;
    width: 100%;
    height: 100vh;
    margin-top: -90px;
    background-image: url('../../img/bg1.png');
    background-size: cover;
    position: relative;
    overflow: auto;
    .cycle-wrapper {
        padding: 90px 30px 0;
        color: #FFFFFF;
        .content {
            padding: 5px;
            margin: 0 35px;
            .con-title {
                font-size: 16px;
                width: 100px;
                height: 30px;
                background: #ffffff;
                border-radius: 15px;
                color: rgb(103, 103, 103);
                text-align: center;
                line-height: 30px;
                margin: 10px 0 20px;
            }
            .con-text {
                font-size: 16px;
                line-height: 35px;
                text-indent: 30px;
                letter-spacing: 2px;
            }
        }
    }
}

// 移动端样式
@media screen and (max-width:650px) {
    .home-bg1 {
        margin-top: -44px;
        .cycle-wrapper {
            padding: 44px 0px;
            .content {
                padding: 5px;
                margin: 0 35px;
                .con-title {
                    font-size: 14px;
                }
                .con-text {
                    font-size: 14px;
                    line-height: 35px;
                }
            }
        }
    }
}
</style>
<style>
/* 移动端样式 */
@media screen and (max-width:650px) {
    .el-breadcrumb__inner {
        font-size: 14px !important;
    }
    .el-breadcrumb__separator {
        font-size: 14px !important;
    }
}
</style>